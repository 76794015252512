<template>
  <div class="_wrap">
    <div class="_banner">
      <img
        :src="$IMG_URL + $store.state.webData.top_image.about_list_image"
        alt="八路军驻洛办事处纪念馆-官方网站"
      />
    </div>

    <div class="_jng">
      <div class="_auto">
        <div class="_nr">
          <div class="_left" id="jng">
            <div class="_top">
              <h4>八路军驻洛办事处纪念馆</h4>
            </div>

            <div class="_bo">
              <div class="_cont">
                <div class="_time">
                  <p class="_impact">
                    开馆时间：{{ $store.state.webData.other_data.open_time }}
                  </p>
                  <p class="_impact">
                    停止入场时间：{{
                      $store.state.webData.other_data.stop_time
                    }}
                  </p>
                  <p class="_impact">
                    闭馆时间：{{ $store.state.webData.other_data.close_time }}
                  </p>
                </div>
                <div class="_ad">
                  <p>地址：{{ $store.state.webData.other_data.address }}</p>
                  <p class="_impact">
                    咨询电话：{{ $store.state.webData.other_data.tel }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="_right">
            <div
              class="_r_t"
              v-html="$store.state.webData.other_data.right_content"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="_dsj" id="dsj">

            <h4 class="_tit">大事记</h4>
            <div class="swiper-container swiper-container1" ref="floor1Swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item, index) in bigList" :key="index">

                        <div class="_li">
                            <div class="_cli">

                                <p class="_p">
                                    {{ item.content }}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="swiper-pagination"></div>
            </div>


            <div class="_year">
                <div class="_xian">
                    <div class="_numList">
                        <div :class="{ _num: true, _active: current == index, _impact: true }"
                            v-for="(item, index) in yearList" :key="index" @click="changeYear(item, index)">{{ item }}</div>

                    </div>

                </div>
            </div>

            <div class="_jt">

                <img @click="prv" src="@/assets/image/about-img/guanyu_dashiji_left.png" alt="八路军驻洛办事处纪念馆-官方网站">


                <img @click="next" src="@/assets/image/about-img/guanyu_dashiji_right.png" alt="八路军驻洛办事处纪念馆-官方网站">

            </div>










        </div> -->

    <!-- <div class="_zjsz" id="zjsz">
            <div class="_auto">
                <div class="_nr">
                    <div class="_left">
                        <div class="_cont">
                            <h4>机构设置</h4>

                            <div class="_list">
                                <div :class="{ _li: true, _active: perIndex == index }" v-for="(item, index) in IS_cate"
                                    :key="index" @click="changePer(item, index)">{{ item.name }}</div>

                            </div>
                        </div>
                    </div>
                    <div class="_right">

                        <div class="_li" v-for="(item, index) in isList" :key="index">
                            <h4>{{ item.name }}</h4>
                            <p>{{ item.position }}</p>
                        </div>
                    </div>


                </div>

            </div>
        </div> -->

    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { memorabilia, about, IS } from "@/api/getData";
import Swiper from "swiper";
export default {
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: "八路军驻洛办事处纪念馆-关于",
      // meta: [
      //     { name: "keywords", content: '八路军驻洛办事处纪念馆,洛八办' },
      //     { name: "description", content: '八路军驻洛办事处纪念馆' },
      // ]
    };
  },

  data() {
    return {
      title: "",
      obj: {},
      aboutData: {},
      current: 0,
      perIndex: 0,
      yearList: [2023, 2022, 2021, 2020, 2019, 2018, 2017],
      IS_cate: [],
      bigList: [],
      list: [
        {
          name: "高级管理人员信息",
        },
        {
          name: "监理会人员信息",
        },
        {
          name: "理事会人员信息",
        },
      ],
      isList: [],
      queryParams: {
        year: "",
      },
      Isparams: {
        id: "",
      },
    };
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      let title = to.query.tit;
      this.title = title;
      for (let i in this.obj) {
        if (this.title == i) {
          this.$scrollToSection(this.obj[i]);
          return;
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // 平滑滚动
          });
        }
      }
    },
  },
  async mounted() {
    // await this.getAbout()
    // await this.memorabilia()
    // await this.IS()
    this.initSwiper();
    this.jumpId();
  },
  created() {},
  methods: {
    jumpId() {
      const title = this.$route.query.tit;
      const obj = {
        纪念馆概况: "jng",
        大事记: "dsj",
        机构设置: "zjsz",
      };
      this.obj = obj;

      for (let i in obj) {
        if (title == i) {
          this.$scrollToSection(obj[i]);
          return;
        } else {
          window.scrollTo({
            top: 0,
            behavior: "smooth", // 平滑滚动
          });
        }
      }
    },
    changeYear(item, index) {
      this.current = index;
      this.queryParams.year = item;
      this.memorabilia();
    },
    changePer(item, index) {
      this.perIndex = index;
      this.Isparams.id = item.id;
      this.IS();
    },
    prv() {
      if (this.current != 0) {
        this.current -= 1;

        this.queryParams.year = this.yearList[this.current];
        this.memorabilia();
      }
    },
    next() {
      if (this.yearList.length != this.current + 1) {
        this.current += 1;
        this.queryParams.year = this.yearList[this.current];
        this.memorabilia();
      }
    },

    initSwiper() {
      new Swiper(".swiper-container1", {
        slidesPerView: 2.7,
        slidesOffsetBefore: 295,
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
          // progressbarFillClass : 'my-pagination-progressbar-fill',
        },
        freeMode: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },

    async getAbout() {
      await about().then((res) => {
        console.log(res);
        this.yearList = res.data.years;
        this.IS_cate = res.data.IS_cate;
        this.queryParams.year = this.yearList[0];
        this.Isparams.id = this.IS_cate[0].id;
      });
    },

    async memorabilia() {
      await memorabilia(this.queryParams).then((res) => {
        console.log(res);
        this.bigList = res.data;
      });
    },

    async IS() {
      await IS(this.Isparams).then((res) => {
        console.log(res);
        this.isList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

@mixin ba_cont {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: white;
  transform: rotate(45deg);
}

._wrap {
  width: 100%;
  overflow: hidden;
  background-color: #fdfaf6;
}

._banner {
  width: 100%;
  height: 700px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

._auto {
  width: 75%;
  margin: 0 auto;
  height: 100%;
}

._jng {
  background-image: url("../../assets/image/about-img/guanyu_bg1.png");
  // background-size: ;
  background-repeat: no-repeat;
  background-position: 0 378px;
  width: 100%;
  // height: 750px;

  ._nr {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  ._left {
    width: 39%;
    margin-top: -125px;

    ._top {
      background-color: #b41e1e;

      h4 {
        padding: 37px 40px 40px;
        text-align: center;
        font-size: 36px;
        color: white;
      }
    }

    ._bo {
      background-color: #b63535;

      ._cont {
        padding: 40px;

        ._time {
          color: white;
          margin-bottom: 40px;

          p {
            font-size: 24px;

            margin: 10px;
          }
        }

        ._ad {
          p {
            font-size: 18px;
            color: #ffa2a2;
            margin: 10px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  ._right {
    width: 59%;
    margin-top: 38px;
    // height: 90%;
    // overflow-y: auto;

    ._r_t {
      margin-bottom: 20px;
      img {
        width: 95%;
      }

      p {
        font-size: 20px;
        color: #333;
        margin-top: 30px;
        letter-spacing: 1px;
      }
    }
  }

  ._right::-webkit-scrollbar {
    width: 8px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
    background-color: transparent;
  }

  ._right::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 20px;
    // background: #E5E5E5;
    background: #b41e1e;
  }
}

._dsj {
  width: 100%;
  height: 814px;
  background-image: url("../../assets/image/about-img/Group guanyu_bg_dashiji.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  ._tit {
    font-size: 36px;
    color: white;

    display: flex;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    margin-top: 74px;
    margin-bottom: 87px;
  }

  ._tit::before {
    @include ba_cont;
    margin-right: 20px;
  }

  ._li {
    // width: 547px;
    width: 85%;

    word-wrap: break-word;
    // height: 240px;
    // padding: 0 124px 0 44px;
    // margin-left: 143px;

    ._cli {
      // margin-left: 143px;
    }

    ._h4 {
      font-size: 36px;
      color: white;
      margin-bottom: 10px;
      position: relative;
      letter-spacing: 1px;
    }

    ._h4::after {
      // content: '';
      // display: inline-block;
      // width: 1px;
      // height: 34px;
      // background-color: #D1A08A;
      // position: absolute;
      // right: 0;
    }

    ._p {
      width: 78%;
      font-size: 20px;
      color: #d68f70;
      line-height: 30px;
    }
  }

  ._year {
    margin-top: 79px;

    ._xian {
      width: 100%;
      border-bottom: 1px solid #ae6240;

      ._numList {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        margin-left: 250px;
        margin-right: 77px;
      }

      ._num {
        position: relative;
        color: #d1a08a;
        font-size: 24px;
        letter-spacing: 1px;
        cursor: pointer;
      }

      ._num::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #d68f70;
        position: absolute;
        bottom: -15px;
        left: 50%;
        margin-left: -5px;
      }

      ._active {
        color: white;
      }

      ._active::before {
        background-color: #fff;
      }
    }
  }

  ._jt {
    text-align: center;
    margin-top: 37px;

    img {
      margin: 0 12px;
      cursor: pointer;
    }
  }
}

._zjsz {
  background-image: url("../../assets/image/about-img/guanyu_bg_jigou2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 800px;

  ._auto {
    width: 75%;
    margin: 0 auto;

    ._nr {
      display: flex;

      ._left {
        width: 283px;
        height: 410px;
        background-image: url("../../assets/image/about-img/guanyu_bg_jigou1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;

        ._cont {
          padding: 53px 0 49px 49px;

          h4 {
            color: white;
            font-size: 36px;
            display: flex;
            align-items: center;
            margin-left: -29px;
          }

          h4::before {
            @include ba_cont();
            margin-right: 20px;
          }

          ._list {
            margin-top: 50px;

            ._li {
              color: #e49a9a;
              font-size: 24px;
              margin: 24px 0;
              cursor: pointer;
              position: relative;
            }

            ._active {
              color: white;
            }

            ._active::after {
              content: "";
              display: inline-block;
              width: 0;
              height: 0;
              border-top: 7px solid transparent;
              border-right: 7px solid transparent;
              border-left: 7px solid #fff;
              border-bottom: 7px solid transparent;
              position: absolute;
              right: 10px;
              top: 10px;
            }
          }
        }
      }

      ._right {
        flex: 1;
        background-color: #fff;
        margin-top: 73px;

        ._li {
          display: flex;
          justify-content: space-between;
          padding: 30px 45px 30px 68px;
          border-bottom: 1px solid #eee;

          h4 {
            font-size: 20px;
            display: flex;
            align-items: center;
          }

          h4::before {
            @include ba_cont();
            border: 1px solid #b41e1e;
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.swiper-container1 {
  // margin-left: 295px;
  height: 347px;
}

::v-deep .swiper-pagination-progressbar {
  width: 25%;
  bottom: 0;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  background: #ad6f54;
  height: 8px;
  border-radius: 10px;
}

::v-deep .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #e2ac95;
}

@include respondTo("phone") {
  ._banner {
    height: 300px;
  }

  ._jng {
    background-position: inherit;

    ._nr {
      display: block;

      ._left{
        width: 100%;
        margin-top: -55px;
        position: relative;
        z-index: 5;
        ._top{
            h4{
                padding: 10px;
                font-size: 24px;
                letter-spacing: 1px;
            }
        }
        ._bo{

            ._cont{
                padding: 20px 40px;
                ._time{
                    margin-bottom: 20px;

                    p{
                        font-size: 20px;
                        margin: 5px;
                    }
                }

                
            }
        }
      }

      ._right{
        width: 100%;
        padding: 0 8px;
        margin-top: 20px;
        ._r_t{
            margin-bottom: 40px;
        }
      }
    }
  }
  ._auto {
    padding: 0 8px;
  }
}
</style>